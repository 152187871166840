.fly-element{
    pointer-events: none;
}
html {
    scroll-behavior: smooth;
  }
.anr_captcha_field{
	display: none;
}
::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: #000;
 }
  
::selection {
    color: #fff;
    background: #000;
}
body{
    span.wpcf7-list-item{
        margin: 0;
    }
}
a {
    color: #fff;

    &:hover {
        text-decoration: none;
        color: #fff;
    }
}
::-webkit-scrollbar {
    width: 7px;
    background-color: #fff;

}
::-webkit-scrollbar-thumb {
    background-color: #8b7041;
    background-size: 100% 300%;
    border-radius: 0px;
    transition: .3s;
}