body {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: $black;
    text-align: left;
    background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}
p {
    margin-bottom: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}
label {
    margin-bottom: 0;
}
ol, ul, dl {
    margin-bottom: 0;
}
ul {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}
input,button,textarea {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    background: transparent;
}
