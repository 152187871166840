$grid-breakpoints: (
    xs: 390px,
    sm: 586px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1340px,
);

$container-max-widths: (
    sm: 570px,
    md: 720px,
    lg: 940px,
    xl: 1150px,
    xxl: 1310px,
);

@import "core/variables"; 
@import "core/bootstrap-func"; 
@import "core/pixelwork"; 

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Open+Sans:wght@400;700&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-display: swap;
    background: #180823;
    overflow-x: hidden;
}

@import "theme/mixins";
@import "theme/main";
@import "theme/owl_critical_css";


@import "enigma_labs/el";
