nav {
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: .2s;
    background: rgba(#0f0517, .45);
    border-bottom: 1px solid rgba(#f5f5f5, .1);
    height: 99px;
    //backdrop-filter: blur(4px);

    .container {
        height: 100%;
    }

    .row {
        position: relative;
        align-items: center;
        flex-wrap: nowrap;
        height: 99px;

        .logo {
            margin-right: 15px;

            img {
                max-width: 237px;
                height: auto;
            }

            @include hover;
        }

        .menu {
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: auto;
            justify-content: end;
            width: 100%;
            position: relative;
            padding-right: 18px;
            transition: .2s;

            @include media-breakpoint-down(xl) {
                margin-left: auto;
            }
            
            @include media-breakpoint-down(lg) {
                display: none;
                padding-right: 0;
            }

            .menu-item {
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                padding: 0 12px;

                @include media-breakpoint-down(xl) {
                    padding: 0 8px;
                }

                &.mobile-only {
                    display: none;
                }

                &::after {
                    content: '';
                    left: 50%;
                    position: absolute;
                    height: 3px;
                    top: -39px;
                    transition: .2s;
                    width: 0%;
                    transform: translateX(-50%);
                    background: #b265fa;

                    @include media-breakpoint-down(lg) {
                        top: 40px;
                    }
                }

                &.active {
                    &::after {
                        width: 85%;
                        @include media-breakpoint-down(lg) {
                            width: 100%;
                        }
                    }

                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            
                &:hover {
                    &::after {
                        width: 85%;
                        @include media-breakpoint-down(lg) {
                            width: 100%;
                        }
                    }

                    .dropdown {
                        opacity: 1;
                        pointer-events: unset;
                    }
                }

                a {
                    color: #fff;
                    display: flex;
                    position: relative;
                    align-items: center;
                    transition: .2s;
                    font-family: 'Cinzel', serif;
                    font-weight: 600;

                    svg {
                        margin-left: 11px;
                    }

                    &:hover {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }

            .dropdown {
                position: absolute;
                right: 0px;
                transition: 0.2s;
                opacity: 0;
                top: 20px;
                pointer-events: none;
                min-width: 156px;
                z-index: 99;

                &::after {
                    content: "";
                    position: absolute;
                    top: 21px;
                    right: 20px;
                    border-width: 7px;
                    border-style: solid;
                    border-color: transparent transparent #1e082b transparent;
                }

                .inner {
                    margin-top: 35px;
                    padding: 7px 25px;
                    background-color: #1e082b;
                    border-radius: 5px;
                    width: 100%;
                    box-shadow: 0px 0px 16px 0px rgb(72, 42, 77);

                    .items-list {

                        .icon-reddit {
                            margin-right: 14px;
                        }
                        .dropdown-item {
							position: relative;
							display: flex;
							padding: 5px 0;
                            font-size: 13px;
                            color: #c6c6d9;

                            svg {
                                margin-left: 0;
                                margin-right: 9px;
                            }

							&:not(:last-child){
								border-bottom: 1px solid #2d2d37;
                            }

                            // span {
                            //     width: 5px;
                            //     height: 8px;
                            //     background: url('../../img/arrow.png') no-repeat;
                            //     display: block;
                            //     position: relative;
                            //     left: -14px;
                            //     transition: color .2s ease-in-out;
                            // }

                            &:hover {
                                text-shadow: none;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .socials {
            padding: 0 29px;
            position: relative;
            display: flex;
            align-items: center;
            transition: .2s;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            &:before {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
                position: absolute;
                left: 0;
                content: '';
                width: 1px;
                height: 15px;
                background: #ffffff;
                opacity: .15;
            }
        }

        .wallet {
            padding-left: 10px;
            position: relative;
            transition: .2s;
            margin-right: -18px;

            @include media-breakpoint-down(lg) {
                margin-left: auto;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }

            &::before {
                @include media-breakpoint-down(lg) {
                    display: none;
                }
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                left: 0;
                content: '';
                width: 1px;
                height: 15px;
                background: #fff;
                opacity: .15;
            }
        }

        .hamburger {
            margin-top: 5px;
            display: none;
            cursor: pointer;
            transition: .2s;

            @include media-breakpoint-down(lg) {
                display: block;
                margin-left: 30px;
                position: relative;
                z-index: 9999;
            }
            @include media-breakpoint-down(sm) {
                margin-left: auto;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &.mobile-open {
            height: 100%;
            width: 100%;
            //backdrop-filter: blur(5px);
            position: fixed;
            background: rgba(#121216, .9);

            .menu {
                display: flex;
                flex-direction: column;
                position: fixed;
                padding-top: 120px;
                top: 0;
                height: auto;
                left: 0px;

                .menu-item {
                    padding: 20px 0;
                    justify-content: center;
                    height: auto;
                    flex-direction: column;
                    transition: .2s;
                    height: auto;
                    margin-right: 0;

                    @include media-breakpoint-down(xs) {
                        &.mobile-only {
                            display: block;
                        }
                    }

                    &::before {
                        display: none;
                    }

                    &:not(.mobile-only)::after {
                        bottom: 20px;
                    }

                    &.mobile-only::after {
                        display: none;
                    }

                    .dropdown {
                        display: none;
                        position: relative;
                        top: 0;
                        right: 0;

                        &::after {
                            display: none;
                        }

                        .elements {
                            display: none;
                        }

                        .inner {
                            padding: 0;
                            border: none;
                            background: transparent;
                            box-shadow: none;
                            margin-top: 0;

                            .items-list {
                                padding-top: 20px;
                            }

                            .dropdown-item {
                                border-bottom: none !important;
                                justify-content: center;

                                span {
                                    padding-left: 0;
                                }
                            }
                        }
                    }

                    &:hover {
                        .dropdown {
                            display: block;
                        }

                        &.drp {
                            &::after {
                                display: none;
                            }
                        }
                    }

                    &.open .dropdown { 
                        display: block;
                        opacity: 1;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                .wallet {
                    display: block;
                }
            }

            @include media-breakpoint-down(xs) {
                .wallet {
                    display: none;
                }
            }
        }
    }
}

header {
    padding-top: 150px;
    padding-bottom: 90px;   
    transition: .2s;
    background: url('../../img/welcome.jpg') top center;
    background-size: cover;
    min-height: 1003px;
    position: relative;
    transition: .2s;

    &#whitelist-gleam {
      min-height: unset;
      background: unset;
      padding-bottom: 20px;
      padding-top: 80px;

      .header-content {
        width: unset;
        margin: auto;
      }

      .logo img {
        display: block;
      }
    }

    @include media-breakpoint-down(sm) {
        background: url('../../img/welcome-mobile.jpg');
    }

    @include media-breakpoint-down(sm) {
        background-position-x: 73%;
    }

    .btn-large {
        transform: scale(1);
        animation: pulse 3s infinite;
        @include media-breakpoint-down(sm) {
            animation: pulseMobile 4s infinite;
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
            filter: drop-shadow(0px 0px 0px rgba(#fff1cb, .5));
        }
        50% {
            transform: scale(1.03);
            filter: drop-shadow(0px 0px 20px rgba(#fff1cb, .5));
        }
        100% {
            transform: scale(1);
            filter: drop-shadow(0px 0px 0px rgba(#fff1cb, .5));
        }
    }

    @keyframes pulseMobile {
        0% {
            transform: scale(1);
            filter: drop-shadow(0px 0px 0px rgba(#fff1cb, .35));
        }
        50% {
            transform: scale(1.03);
            filter: drop-shadow(0px 0px 15px rgba(#fff1cb, .35));
        }
        100% {
            transform: scale(1);
            filter: drop-shadow(0px 0px 0px rgba(#fff1cb, .35));
        }
    }

    .anim-bg {
        position: absolute;
        min-width: 100%;
        max-width: 100%;
        overflow: hidden;
        z-index: 99;
        top: -63px;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 1066px;
        pointer-events: none;
        object-fit: cover;

        @media (min-width: 1921px) {
            display: none;
            pointer-events: none;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .go-bottom {
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99;

        @include hover;
    }

    @include media-breakpoint-down(md) {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .header-content {
        max-width: 750px;
        margin-left: -30px;

        @include media-breakpoint-down(lg) {
            width: 100%;
            margin: 0 auto;
        }

        a.logo {
            z-index: 99;
            display: block;
            margin-top: 30px;
            position: relative;

            img {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                }           
            }

            @include media-breakpoint-down(md) {
                video {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                img {
                    width: 100%;
                    height: auto
                }
            }
        }
          
        .header-subtext {
            margin-top: -20px;
            font-family: 'Cinzel', serif;
            font-weight: 600;
            font-size: 30px;
            color: #f0e3e8;
            text-transform: uppercase;
            line-height: 1.467;
            text-shadow: 0px 0px 28.13px rgba(16, 5, 22, 0.67);
            text-align: center;
            position: relative;
            z-index: 99;

            @include media-breakpoint-down(md) {
                text-shadow: 1px 1px 5px #000, 1px 1px 5px #000;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 50px;
            }

            span {
                text-decoration: underline;
            }
        }

        .header-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
            position: center;
            z-index: 99;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            .discord-join {
                display: flex;
                align-items: center;
                font-size: 13px;
                text-transform: uppercase;
                color: #d5c8cd;
                font-family: 'Cinzel', serif;
                font-weight: 600;
                text-shadow: 0px 0px 28.13px rgba(16, 5, 22, 0.67),0px 1px 0px rgba(0, 0, 0, 0.22);
                transition: .2s;

                @include media-breakpoint-down(md) {
                    text-shadow: 1px 1px 5px #000, 1px 1px 5px #000;
                }

                i {
                    margin-right: 13px;
                    margin-left: 11px;
                    transition: .2s;
                    @include media-breakpoint-down(md) {
                        filter: drop-shadow(1px 1px 5px #000);
                    }

                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                    }
                }

                img {
                    margin-left: 13px;
                    transition: .2s;
                    position: relative;
                    left: 0;
                }

                &:hover {
                    color: #eea8c3;

                    img {
                        left: 5px;
                    }
                }
            }
        }
    }
}

.trust {
    padding: 125px 0;
    overflow: hidden;
    @include media-breakpoint-down(md) {
        padding: 50px 0;
    }

    @include media-breakpoint-down(lg) {
        text-align: center;
    }

    background: url('../../img/trust-bg.png');
    background-size: cover;

    @include media-breakpoint-down(lg) {
        background-size: cover;

        .description {
            padding-right: 0px;
        }
    }

    .separator {
        margin-top: 87px;
    }

    .btn-large {
        margin-left: -19px;
        margin-top: 29px;

        @include media-breakpoint-down(lg) {
            margin: 29px auto 0 auto;
        }
    }

    .image-frame {
        @include media-breakpoint-down(lg) {
            max-width: 677px;
            margin: 30px auto 0 auto;
        }
        img {
            height: 379px;
            object-fit: contain;

            @include media-breakpoint-down(md) {
                height: auto;
            }
        }
    }

    .meet-row {
        padding-top: 60px;
    }

    .btn-large.center {
        margin-top: 15px;
    }
}

.protectors {
    position: relative;
    z-index: 5;
    background: url('../../img/protectors-bg.png') top center no-repeat;
    background-size: cover;
    max-height: 505px;

    @include media-breakpoint-down(lg) {
        max-height: 100%;
        text-align: center;
    }

    .char-image {
        top: -39px;

        @include media-breakpoint-down(sm) {
            img {
                width: 100%;
                height: auto;
            }
        }

        &::before {
            position: absolute;
            width: 492px;
            height: 460px;
            background: url('../../img/red-char-orn.png');
            content: '';
            left: -182px;
            top: 49px;
            
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &::after {
            position: absolute;
            width: 873px;
            height: 871px;
            background: url('../../img/red-hue.png');
            content: '';
            left: -263px;
            top: -88px;
            z-index: 99;

            @include media-breakpoint-down(xxl) {
                display: none;
            }
        }
    }
}

.satanic {
    position: relative;
    z-index: 6;
    background: url('../../img/satanic-bg.png') top center no-repeat;
    background-size: cover;
    min-height: 480px;

    @include media-breakpoint-down(md) {
        max-height: 100%;
        text-align: center;
    }

    .char-image {
        position: absolute !important;
        right: 0;
        top: -120px;

        @include media-breakpoint-down(xxl) {
            right: 112px;
        }

        @include media-breakpoint-down(lg) {
            right: 60px;
        }

        @media (max-width: 450px) {
            right: 30px;
        }

        @include media-breakpoint-down(lg) {
            order: 1;
            margin-bottom: -105px;
        }

        &::before {
            position: absolute;
            width: 456px;
            height: 429px;
            background: url('../../img/yellow-char-orn.png');
            content: '';
            left: 148px;
            top: 139px;

            @include media-breakpoint-down(xxl) {
                content: '';
                left: 33px;
                top: 139px;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &::after {
            position: absolute;
            width: 826px;
            height: 871px;
            background: url('../../img/yellow-hue.png');
            content: '';
            left: -127px;
            top: -88px;
            z-index: 99;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            @include media-breakpoint-down(xxl) {
                left: -338px;
                opacity: .24;
                top: -88px;
                z-index: 99;
            }
        }
    }
}

.forgotten {
    position: relative;
    z-index: 7;
    background: url('../../img/forgotten-bg.png') top center, no-repeat;
    background-size: cover;
    max-height: 600px;

    @include media-breakpoint-down(lg) {
        max-height: 100%;
        text-align: center;
    }

    .char-image {
        top: -43px;

        @include media-breakpoint-down(sm) {
            img {
                width: 100%;
                height: auto;
            }
        }

        &::before {
            position: absolute;
            width: 468px;
            height: 463px;
            background: url('../../img/brown-char-orn.png');
            content: '';
            left: -170px;
            top: 47px;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &::after {
            position: absolute;
            width: 873px;
            height: 871px;
            background: url('../../img/brown-hue.png');
            content: '';
            left: -308px;
            top: -133px;
            z-index: 99;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}

.playstyle {
    padding: 145px 0 125px 0;
    background: url('../../img/playstyle-bg.png') top center no-repeat;
    background-size: cover;

    .description {
        padding-bottom: 112px;
    }

    .splide {
        // overflow: hidden;
        // position: relative;
        // width: 100vw;

        .splide__track {
            // display: flex;
            // width: 2100px;
            // animation: scrollAnimationMobile 45s linear infinite;
            // animation : scroll 5s linear infinite;
            // width: calc(150px * 14);
        }

        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(calc(-150px * 7)); }
        }

        @keyframes scrollAnimationMobile {
            0% {
                transform: translateX(0)
            }
            to {
                transform: translateX(-1050px)
            }
        }

        @keyframes scrollAnimationPc {
            0% {
                transform: translateX(0)
            }
            to {
                transform: translateX(-1782px)
            }
        }

        @media screen and (min-width: 1024px) {
            .splide__track {
                // -webkit-animation: scrollAnimationPc 45s linear infinite;
                // animation: scrollAnimationPc 45s linear infinite;
            }
        }

        .splide__slide {
            //display: block;
            // width: 299px;
            height: 396px;
            background-image: url('../../img/slide-frame.png');
            background-size: contain;
            background-position: top center;
            position: relative;
            margin: 0 15px;

            .slider-img {
                border: 2px solid transparent;
                border-image: linear-gradient(90deg, #5c4418, #eacc80, #805c24);
                border-image-slice: 1;
                left: 7px;
                top: 7px;
                position: absolute;
                height: 383px;

                img {
                    display: none;
                }

                video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                @include media-breakpoint-down(md) {
                    img {
                        display: block;
                        max-height: 379px;
                    }
                    video {
                        display: none;
                    }
                }

                @include hover;
            }
        }
    }

    .favor-row {
        justify-content: space-between;
        flex-wrap: wrap;

        @include media-breakpoint-down(xl) {
            max-width: 640px;
            margin: 0 auto;

            .favor-box {
                margin-bottom: 35px;
            }
        }

        @include media-breakpoint-down(lg) {
            justify-content: center;
            max-width: 100%;
        }
    }

    .separator {
        margin-top: 110px;
    }

    .claim-favor {
        padding-top: 40px;
    }

    .favor-box {
        width: 298px;
        height: 293px;
        background: url('../../img/favor-box.png') no-repeat;
        position: relative;
        text-align: center;
        cursor: pointer;
        transition: .2s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;

        .favor-icon {
            padding-top: 0;
            margin-top: -53px;
        }

        @include media-breakpoint-down(lg) {
            margin: 0 30px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 auto;
        }

        &:hover {
            filter: brightness(120%);
        }

        .favor-icon {
            padding-top: 38px;

            img {
                filter: drop-shadow(2px 4px 35px #553057);
                @include media-breakpoint-down(sm) {
                    max-width: 113px;
                }
            }
        }

        .favor-heading {
            font-size: 18px;
            color: #f0e7ea;
            line-height: 1.611;
            text-shadow: 0px 1px 0px rgba(127, 42, 71, .004);
            font-weight: bold;
        }

        .favor-subheading {
            font-size: 14px;
            color: #f0e7ea;
            line-height: 1.611;
            text-shadow: 0px 1px 0px rgba(127, 42, 71, .004);
            font-weight: 400;
        }
    }
}

.future {
    padding: 125px 0;
    background: url('../../img/future-bg.png') top center no-repeat;
    background-size: cover;

    .image-frame {
        margin-top: 112px;
    }

    .description {
        max-width: 86%;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }
}

.char-box {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(lg) {
        padding-bottom: 70px;
        flex-direction: column;
    }

    .char-image {
        position: relative;
        z-index: 9;
        @include media-breakpoint-down(lg) {
            top: -35px;
        }
        @include media-breakpoint-down(lg) {
            position: relative !important
        }

        img {
            position: relative;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .char-frame-text-right {
        padding: 30px 0;
        position: relative;
        border: 2px solid transparent;
        border-image: linear-gradient(90deg, #5c4418, #eacc80, #805c24);
        border-image-slice: 1;
        margin-left: -110px;
        margin-top: -10px;
        padding-left: 124px;
        width: 100%;

        @include media-breakpoint-down(lg) {
            padding-left: 98px;
        }

        @include media-breakpoint-down(lg) {
            padding: 30px;
            margin: 0 auto;
        }
        
        .description {
            color: #f0e7ea;
            max-width: 837px;

            small {
                font-size: 14px;
            }
        }

        &::after {
            width: 169px;
            height: 169px;
            background: url('../../img/top-right-corner.png');
            position: absolute;
            top: -14px;
            right: -14px;
            content: '';

            // @include media-breakpoint-down(xl) {
            //     display: none;
            // }
        }
    }

    .char-frame-text-left {
        padding: 30px 0;
        position: relative;
        border: 2px solid transparent;
        border-image: linear-gradient(90deg, #5c4418, #eacc80, #805c24);
        border-image-slice: 1;
        padding-left: 50px;
        width: 960px;
        @include media-breakpoint-down(xxl) {
            width: 940px;
        }
        @include media-breakpoint-down(xl) {
            padding-right: 145px;
        }
        @include media-breakpoint-down(lg) {
            width: 590px;
            padding: 30px 20px 30px 50px;
        }
        margin-top: 70px;

        @include media-breakpoint-down(lg) {
            order: 2
        }

        @include media-breakpoint-down(md) {
            padding: 30px;
            text-align: center;
            width: 100%;
        }

        .description {
            color: #f0e7ea;
            max-width: 837px;

            small {
                font-size: 14px;
            }
        }

        &::after {
            width: 169px;
            height: 169px;
            background: url('../../img/top-right-corner.png');
            transform: scale(-1,1);
            position: absolute;
            top: -14px;
            left: -14px;
            content: '';

            // @include media-breakpoint-down(xl) {
            //     display: none;
            // }
        }
    }
}

.join {
    padding: 58px 0;
    background: url('../../img/join-bg.png') top center no-repeat;
    background-size: cover;
    min-height: 290px;
    
    @include media-breakpoint-down(sm) {
        background-size: cover;
    }

    .join-buttons {
        display: flex;
        align-items: center;
        max-width: 500px;
        justify-content: space-between;
        margin: 30px auto 0 auto;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
}

.newsletter {
    padding: 125px 0 100px 0;
    background: url('../../img/newsletter-bg.png') top center no-repeat;
    background-size: cover;

    .socials {
        display: flex;
        justify-content: center;
    }

    .col-text {
        padding: 25px 0;
        font-weight: 100;
        color: #f0e7ea;
    }

    .form-newsletter-group {
        max-width: 455px;
        margin: 0 auto;
        position: relative;
        margin-top: 40px;

        @include media-breakpoint-down(xs) {
            max-width: 100%;
        }

        .btn-purple {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #8719ff;
            background: #9327eb;
            font-size: 14px;
            color: #fff;
            padding: 12px 36px;
            position: absolute;
            right: 5px;
            top: 5px;
            outline: none;
            cursor: pointer;

            @include media-breakpoint-down(xs) {
                position: relative;
                margin: 10px auto 0 auto;
            }

            @include hover
        }

        input {
            background: #0f0414;
            border: 0;
            outline: none;
            height: 56px;
            width: 100%;
            padding: 0 190px 0 30px;
            color: #f0e7ea;
            @include media-breakpoint-down(xs) {
                padding: 0 30px;
            }

            &::placeholder {
                color: #f0e7ea;
                font-size: 14px;
            }
        }
    }
}

footer {
    .footer-copyright {
        background: #120418;
        font-weight: 100;
        font-size: 12px;
        color: #fff;
        padding: 1px 0;
        
        @include media-breakpoint-down(md) {
            text-align: center;
        }

        .copyright-left {
            font-size: 12px;
            color: #f0e9ea;
            padding: 9px 0;

            span {
                color: #fff;
                font-weight: bold;
            }
        }

        img {
          max-height: 40px;
        }

        .copyright-right {
            font-size: 12px;
            color: #f0e9ea;
            text-align: right;
            padding: 9px 0;

            @include media-breakpoint-down(md) {
                text-align: center;
            }

            span {
                color: #9327ec;
                font-weight: bold;
            }
        }

    }
}

.text-center {
    text-align: center;
}
.image-frame2{
    position: relative;
    border: 2px solid transparent;
    border-image: linear-gradient(90deg, #5c4418 , #eacc80, #805c24);
    border-image-slice: 1;

    .iframe-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 100px;
            left: 0;
            width: 100%;
            height: 100%;
        }

        video {
          max-width: 100%;
        }
    }

}

.image-frame {
    position: relative;
    border: 2px solid transparent;
    border-image: linear-gradient(90deg, #5c4418 , #eacc80, #805c24);
    border-image-slice: 1;

    .iframe-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        video {
          max-width: 100%;
        }
    }

    &::before {
        width: 169px;
        height: 169px;
        background: url('../../img/top-right-corner.png');
        position: absolute;
        top: -14px;
        right: -14px;
        content: '';
        // @include media-breakpoint-down(xl) {
        //     display: none;
        // }
    }

    &::after {
        width: 169px;
        height: 169px;
        background: url('../../img/left-bottom-corner.png');
        position: absolute;
        left: -14px;
        bottom: -14px;
        content: '';
        // @include media-breakpoint-down(xl) {
        //     display: none;
        // }
    }

    img {
        width: 100%;
        height: auto;
    }
}

.price {
    font-size: 12px;
    color: #40bcff;
    border: 1px solid #1aaaed;
    border-radius: 5px;
    padding: 0px 5px;
    font-weight: 400;
    margin-left: 12px;
}

.heading {
    margin-top: -14px;
    font-size: 42px;
    color: #fff;
    font-family: "Cinzel", sans-serif;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        font-size: 40px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 35px;
    }

    &::after {
        position: absolute;
        width: 150px;
        height: 4px;
        bottom: -10px;
        background: #8719ff;
        border: 1.45px;
        left: 3px;
        content: '';
    }

    &.center {
        text-align: center;

        &::after {
            left: 50%;
            transform: translateX(-50%)
        }
    }


    @include media-breakpoint-down(lg) {
        &::after {
            left: 50%;
            transform: translateX(-50%)
        }
    }
}

.small-heading {
    font-size: 42px;
    color: #fff;
    font-family: "Cinzel", sans-serif;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        font-size: 40px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 35px;
    }

    &::after {
        position: absolute;
        width: 90px;
        height: 2px;
        bottom: -10px;
        background: #8719ff;
        border: 1.45px;
        left: 3px;
        content: '';

        @include media-breakpoint-down(lg) {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.center {
        text-align: center;

        &::after {
            left: 50%;
            transform: translateX(-50%)
        }
    }
}

.separator {
    max-width: 1314px;
    height: 102px;
    background: url('../../img/separator.png') top center no-repeat;
    margin: 0 auto;
    background-size: contain;
}

.description {
    color: #d5c8cd;
    line-height: 2;
    padding-top: 35px;
    padding-right: 20px;

    @include media-breakpoint-down(lg) {
        padding-right: 0;
    }

    &.max-width {
        max-width: 960px;
        margin: 41px auto 0 auto;
        padding-top: 0;
        padding-right: 0;
    }

    .important {
        color: #b953fa;
    }

    .white {
        color: #fff;
    }

    .underline {
        text-decoration: underline;
    }
}

.btn-normal {
    width: 220px;
    height: 91px;
    background: url('../../img/btn-normal.png');
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;

    span {
        background: -webkit-linear-gradient(right, #edd5a3, #dbbf76);
        -webkit-background-clip: text;
        -webkit-font-smoothing: antialiased;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(2px 1px 0px rgba(0, 0, 0, .4)) drop-shadow(0px 0px 13px rgba(247, 230, 164, .4));
        font-weight: 600;
        font-family: 'Cinzel', serif;
    }

    @include hover;
}

.btn-large {
    width: 250px;
    height: 97px;
    background: url('../../img/btn-large.png');
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;

    &.center {
        margin: 0 auto;
    }

    span {
        background: -webkit-linear-gradient(right, #edd5a3, #dbbf76);
        -webkit-background-clip: text;
        -webkit-font-smoothing: antialiased;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(2px 1px 0px rgba(0, 0, 0, .4)) drop-shadow(0px 0px 13px rgba(247, 230, 164, .4));
        font-weight: 600;
        font-family: 'Cinzel', serif;
    }

    @include hover;
}

.btn-explore {
    background: #ecf1f3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    color: #101014;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 7px 26px;
    transition: .2s ease;
    border: 1px solid transparent;

    &:hover {
        color: #ecf1f3;
        background: #101014;
        border: 1px solid #ecf1f3;

    }
}

.icon-discord {
    width: 17px;
    height: 13px;
    background: url('../../img/icons/discord.png') no-repeat;
    display: block;
    background-size: contain;
    margin-right: 14px;
    transition: .2s ease all;
    opacity: .4;

    &:hover {
        opacity: 1;
    }
}

.icon-discord-large {
    width: 30px;
    height: 22px;
    background: url('../../img/icons/discord.png') no-repeat;
    background-size: contain;
    display: block;
    transition: .2s ease all;
}

.icon-twitter {
    width: 15px;
    height: 13px;
    background: url('../../img/icons/twitter.png') no-repeat;
    background-size: contain;
    display: block;
    margin-right: 14px;
    transition: .2s ease all;
    opacity: .4;

    &:hover {
        opacity: 1;
    }
}

.icon-medium {
    width: 16px;
    height: 12px;
    background: url('../../img/icons/medium.png') no-repeat;
    background-size: contain;
    display: block;
    margin-right: 14px;
    transition: .2s ease all;
    opacity: .4;

    &:hover {
        opacity: 1;
    }
}

.icon-reddit {
    width: 17px;
    height: 16px;
    background: url('../../img/icons/reddit.png') no-repeat;
    background-size: contain;
    display: block;
    transition: .2s ease all;
    opacity: .4;

    &:hover {
        opacity: 1;
    }
}

.icon-instagram {
    width: 16px;
    height: 16px;
    background: url('../../img/icons/instagram.png') no-repeat;
    background-size: contain;
    display: block;
    transition: .2s ease all;
    margin-right: 14px;
    opacity: .4;

    &:hover {
        opacity: 1;
    }
}

.mint-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: center;
    z-index: 99;

}

.mint-exp-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: center;
    z-index: 99;

}

.sm-heading {
    font-size: 20px;
    color: #fff;
    font-family: "Cinzel", sans-serif;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        font-size: 40px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 35px;
    }

    &::after {
        position: absolute;
        width: 90px;
        height: 2px;
        bottom: -10px;
        background: #8719ff;
        border: 1.45px;
        left: 3px;
        content: '';

        @include media-breakpoint-down(lg) {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}


.mint-dec-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: center;
    z-index: 99;

}
.mint-inc-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: center;
    z-index: 99;

}

.btn-inc{
    width: 250px;
    height: 45px;
    background: url('../../img/Plus icon.png') no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;

    &.center {
        margin: 0 auto;
    }

    span {
        background: -webkit-linear-gradient(right, #edd5a3, #dbbf76);
        -webkit-background-clip: text;
        -webkit-font-smoothing: antialiased;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(2px 1px 0px rgba(0, 0, 0, .4)) drop-shadow(0px 0px 13px rgba(247, 230, 164, .4));
        font-weight: 600;
        font-family: 'Cinzel', serif;
    }

    @include hover;
}

.btn-dec {
    width: 200px;
    height: 40px;
    background: url('../../img/Min button.png') no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;

    &.center {
        margin: 0 auto;
    }

    span {
        background: -webkit-linear-gradient(right, #edd5a3, #dbbf76);
        -webkit-background-clip: text;
        -webkit-font-smoothing: antialiased;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(2px 1px 0px rgba(0, 0, 0, .4)) drop-shadow(0px 0px 13px rgba(247, 230, 164, .4));
        font-weight: 600;
        font-family: 'Cinzel', serif;
    }

    @include hover;
}

.socials2{
    padding: 0 29px;
    position: relative;
    display: flex;
    align-items: center;
    transition: .2s;

}

.btn-normal-2 {
    width: 220px;
    height: 91px;
    background: url('../../img/Min button.png') no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    //background-size: 100px 100px;

    @include hover;
}


.btn-normal-1 {
    width: 220px;
    height: 91px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    //background-size: 100px 100px;

    @include hover;
}


.btn-normal-3 {
    width: 220px;
    height: 91px;
    background: url('../../img/Plus icon.png') no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    //background-size: 100px 100px;

    @include hover;
}
.small-heading3 {
    padding-top: 18px;
    font-size: 30px;
    color: #fff;
    font-family: "Cinzel", sans-serif;
    font-weight: 600;
    position: relative;


    @include media-breakpoint-down(sm) {
        font-size: 40px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 35px;
    }

    &::after {
        position: absolute;
        width: 90px;
        height: 2px;
        bottom: -10px;
        background: #8719ff;
        border: 1.45px;
        left: 3px;
        content: '';

        @include media-breakpoint-down(lg) {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.center {
        text-align: center;

        &::after {
            left: 50%;
            transform: translateX(-50%)
        }
    }
}


.small-heading2 {
    padding-top: 18px;
    font-size: 30px;
    color: #fff;
    font-family: "Cinzel", sans-serif;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        font-size: 40px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 35px;
    }


    &.center {
        text-align: center;

        &::after {
            left: 50%;
            transform: translateX(-50%)
        }
    }
}
.header-subtext2 {
    margin-top: -20px;
    font-family: 'Cinzel', serif;
    font-weight: 600;
    font-size: 50px;
    color: #f0e3e8;
    text-transform: uppercase;
    line-height: 1.467;
    text-shadow: 0px 0px 28.13px rgba(16, 5, 22, 0.67);
    text-align: center;
    position: relative;
    z-index: 99;
}

.btn-largeInc {
    width: 250px;
    height: 97px;
    background: url('../../img/Plus icon.png') no-repeat;


    &.center {
        margin: 0 auto;
    }

    span {
        background: -webkit-linear-gradient(right, #edd5a3, #dbbf76);
        -webkit-background-clip: text;
        -webkit-font-smoothing: antialiased;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(2px 1px 0px rgba(0, 0, 0, .4)) drop-shadow(0px 0px 13px rgba(247, 230, 164, .4));
        font-weight: 600;
        font-family: 'Cinzel', serif;
    }

    @include hover;
}
.btn-largeDec {
    width: 250px;
    height: 97px;
    background: url('../../img/Min button.png') no-repeat;


    &.center {
        margin: 0 auto;
    }

    span {
        background: -webkit-linear-gradient(right, #edd5a3, #dbbf76);
        -webkit-background-clip: text;
        -webkit-font-smoothing: antialiased;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(2px 1px 0px rgba(0, 0, 0, .4)) drop-shadow(0px 0px 13px rgba(247, 230, 164, .4));
        font-weight: 600;
        font-family: 'Cinzel', serif;
    }

    @include hover;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;

    padding-left:10px;
    width:100px;


}

.flex-parent {
    display: flex;

}

.jc-center {
    justify-content: center;
    text-align: right;
}

button.margin-right {
    margin-right: 20px;
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}


.divider-image{

	width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

        img {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
        }
    

}


.logo-image {
    img {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }

}
.char-box-new {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(lg) {
        padding-bottom: 70px;
        flex-direction: column;
    }

    .char-image {
        position: relative;
        z-index: 9;
        @include media-breakpoint-down(lg) {
            top: -35px;
        }
        @include media-breakpoint-down(lg) {
            position: relative !important
        }

        img {
            position: relative;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .char-frame-text-right {
        padding: 30px 0;
        position: relative;
        border: 2px solid transparent;
        border-image-slice: 1;
        margin-left: -110px;
        margin-top: -10px;
        padding-left: 124px;
        width: 100%;

        @include media-breakpoint-down(lg) {
            padding-left: 98px;
        }

        @include media-breakpoint-down(lg) {
            padding: 30px;
            margin: 0 auto;
        }

        .description {
            color: #f0e7ea;
            max-width: 837px;

            small {
                font-size: 14px;
            }
        }

        &::after {
            width: 169px;
            height: 169px;
            position: absolute;
            top: -14px;
            right: -14px;
            content: '';

            // @include media-breakpoint-down(xl) {
            //     display: none;
            // }
        }
    }

    .char-frame-text-left {
        padding: 30px 0;
        position: relative;
        border: 2px solid transparent;
        border-image-slice: 1;
        padding-left: 50px;
        width: 960px;
        @include media-breakpoint-down(xxl) {
            width: 940px;
        }
        @include media-breakpoint-down(xl) {
            padding-right: 145px;
        }
        @include media-breakpoint-down(lg) {
            width: 590px;
            padding: 30px 20px 30px 50px;
        }
        margin-top: 70px;

        @include media-breakpoint-down(lg) {
            order: 2
        }

        @include media-breakpoint-down(md) {
            padding: 30px;
            text-align: center;
            width: 100%;
        }

        .description {
            color: #f0e7ea;
            max-width: 837px;

            small {
                font-size: 14px;
            }
        }

        &::after {
            width: 169px;
            height: 169px;
            transform: scale(-1,1);
            position: absolute;
            top: -14px;
            left: -14px;
            content: '';

            // @include media-breakpoint-down(xl) {
            //     display: none;
            // }
        }
    }
}